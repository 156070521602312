import React, { createContext, useState } from "react";
const MyContext = createContext();

export function SurveyTypeContext({ children }) {
  const [surveySubTypeTitle, setSurveySubTypeTitle] = useState("REGULAR");

  const [lite_version, setLiteVersion] = useState(true);
  // Pass an object as the value
  const contextValue = {
    surveySubTypeTitle,
    setSurveySubTypeTitle,
    lite_version,
    setLiteVersion,
  };

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
}

export default MyContext;
