// import { RouteComponent } from "./routes/Routes";
//moenguage event is added
// import { MoEvent } from "./utils/Events";
import { SurveyTypeContext } from "./context/SurveyTypeContext";

import "./global.css";

import React from "react";
import { RouteComponent1 } from "./routes/Routes1";
function App() {
  return (
    <div className="App">
      <SurveyTypeContext>
        <RouteComponent1 />
      </SurveyTypeContext>
    </div>
  );
}

export default App;
